
import { Section } from 'fsxa-api'
import { FSXARenderContentElement } from 'fsxa-pattern-library'
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import BaseIconButton from '../base/BaseIconButton.vue'

@Component({
  name: 'IconCarousel',
  components: {
    BaseIconButton,
    FSXARenderContentElement,
    BaseHeadline: () => import('../base/BaseHeadline.vue'),
  },
})
export default class IconCarousel extends Vue {
  @Prop() headline ?: string

  @Prop({ default: () => [] }) sections! : Section[]

  private isScrollableToLeft : boolean = false

  private isScrollableToRight : boolean = false

  private scrollArea : Element | undefined = undefined

  $refs! : {
    'scroll-area' ?: Element
  }

  mounted () {
    this.scrollArea = this.$refs['scroll-area']
    this.updateScrollElements()
    new ResizeObserver(this.updateScrollElements).observe(this.$el)
  }

  private scrollLeft () {
    if (this.scrollArea) this.scrollArea.scrollLeft -= 250
    this.updateScrollElements()
  }

  private scrollRight () {
    if (this.scrollArea) this.scrollArea.scrollLeft += 250
    this.updateScrollElements()
  }

  private updateScrollElements () {
    if (!this.scrollArea) return
    this.isScrollableToLeft = this.scrollArea.scrollLeft > 0
    this.isScrollableToRight = this.scrollArea.scrollLeft < this.scrollArea.scrollWidth - this.scrollArea.clientWidth
  }
}
